import React from "react";

export default function IsiSticky({scroll}) {
    
    return (
          <div className="isi-sticky">
          <div className="container">
              <div className="left-section">
                  <h6>INDICATION</h6>
                  <p>AMPYRA<sup>®</sup> (dalfampridine) Extended Release Tablets, 10 mg, is the first and only brand prescription
                      medicine indicated to help improve walking in adults with multiple sclerosis (MS). This was
                      demonstrated by an increase in walking speed.</p>
              </div>
              <div className="right-section">
                  <h6>IMPORTANT SAFETY INFORMATION</h6>
                  <p>Do not take AMPYRA if you have ever had a seizure, have certain types of kidney problems, or are
                      allergic to dalfampridine (<span>4-aminopyridine</span>), the active ingredient in AMPYRA.</p>
              </div>
              <div className="scroll-button-section" onClick={scroll}>
                  <span>SEE MORE</span>
                  <svg className="show_desktop" width="26" height="14" viewBox="0 0 26 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.5 1L13 12.5L24.5 1" stroke="#CB6015" strokeWidth="2" />
                  </svg>
                  <svg className="show_mobile" width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.5 1L5.5 5L9.5 1" stroke="#E36F1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>    
              </div>
          </div>
      </div>
    )
}