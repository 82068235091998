import React from "react";
import { Modal } from 'bootstrap';

export default function Footer({ dateCode }) {

  window.addEventListener("load", (event) => {
    // site leave modal window
    document.querySelectorAll('.show_leave_website_modal').forEach((items) => {
      items.addEventListener('click', function (event1) {
        var LeavingButton = document.getElementById('leave_button');
        LeavingButton.setAttribute('href', items.getAttribute('href'));

        // var LeavingModal = new Modal(document.getElementById('LeavingSiteModal'), {
        //   keyboard: true
        // });
        // LeavingModal.show()

        return event1.preventDefault()
      })
    })
  })
  return (
    <>
      <footer>
        <div className="container">
          <div className="logo">
            <a href="https://www.merztherapeutics.com/" className="show_leave_website_modal" id="footer_logo" aria-label="Merz Therapeutics Logo">
              <img src="/assets/images/merz-therapeutics-logo.svg" style={{ maxWidth: "200px" }} />
            </a>
          </div>

          <div className="patient-webite-phone-number-section">
            <div className="patient-website-section">
              <ul className="list-style-none">
                <li><a href="https://www.ampyra.com" className="show_leave_website_modal" id="footer_consumer_site">Patient Website</a></li>
                <li><a href="https://www.ampyra-hcp.com" className="show_leave_website_modal" id="footer_HCP_site">Healthcare
                  Professional Website</a></li>
              </ul>
            </div>
            <div className="phone-number-section">
              <ul className="list-style-none">
                <li><span>ASK QUESTIONS</span> <a href="tel:1-888-881-1918" className="single-word" id="footer_1918">1-888-881-1918</a></li>
                <li>
                  <a href="#" id="footer_print" aria-label="print" onClick={() => window.print()}>
                    <svg width="40" height="41" viewBox="0 0 40 41" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <circle cx="20" cy="20.7998" r="20" fill="#E36F1E" />
                      <path fillRule="evenodd" clipRule="evenodd"
                        d="M12.3806 12.4665C12.3806 11.546 13.2334 10.7998 14.2854 10.7998H25.7135C26.7656 10.7998 27.6183 11.546 27.6183 12.4665V15.2442H29.5237C30.5756 15.2442 31.4284 15.9904 31.4284 16.9109V23.5776C31.4284 24.498 30.5756 25.2442 29.5237 25.2442H27.6183V29.6887C27.6183 30.3024 27.0498 30.7998 26.3486 30.7998H13.6504C12.9492 30.7998 12.3806 30.3024 12.3806 29.6887V25.2442H10.476C9.42408 25.2442 8.57131 24.498 8.57131 23.5776L8.57129 16.9109C8.57129 15.9904 9.42405 15.2442 10.476 15.2442H12.3806V12.4665ZM26.5073 30.0062V23.022H13.4917V30.0062H26.5073ZM30.3166 19.6887C30.3166 20.3023 29.748 20.9115 29.0468 20.9115C28.3455 20.9115 27.7771 20.3023 27.7771 19.6887C27.7771 19.075 28.3455 18.5105 29.0468 18.5105C29.748 18.5105 30.3166 19.075 30.3166 19.6887ZM26.5073 11.5935H13.4917V15.2442H26.5073V11.5935Z"
                        fill="white" />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-links">
            <ul className="list-style-none">
              <li><a href="https://ampyra.com/medication-guide.pdf?v=2" target="_blank" id="footer_med_guide">Medication Guide</a></li>
              <li><a href="/contact" id="footer_contact">Contact Us</a></li>
              <li><a href="https://www.merztherapeutics.com/us/privacy-policy/" target="_blank" id="footer_privacy">Privacy Policy</a></li>
              <li><a href="https://www.merztherapeutics.com/terms-of-use/" target="_blank" id="footer_TOU">Terms of Use</a></li>
              <li><a href="/cookie-notice" id="footer_cookie_notice">Cookie Notice</a></li>
              <li><a href="javascript:void(0)" id="ot-sdk-btn" className="ot-sdk-show-settings">Do Not Sell My Personal Information</a></li>
            </ul>
          </div>
          <hr />
          <div className="footer-notes">
            <div className="left-side">
              <p>Copyright &copy; 2024 Merz Pharmaceuticals, LLC. All rights reserved. AMPYRA is a registered trademark of Merz Pharmaceuticals, LLC</p>
              <p>This site is intended for US residents only.</p>
            </div>
            <div className="right-side">
              <p>{dateCode}</p>
            </div>
          </div>
        </div>
      </footer>
      {/* 
      <div className="modal ampyra-modal fade" id="LeavingSiteModal" tabIndex="-1" aria-labelledby="LeavingSiteModalLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">

            <div className="modal-body">
              <button className="close-button" type="button" data-bs-dismiss="modal" aria-label="Close">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="40" height="40" rx="20" fill="white" />
                  <path d="M13 13L27 27" stroke="#367C2B" strokeWidth="3" strokeLinecap="round" />
                  <path d="M27 13L13 27" stroke="#367C2B" strokeWidth="3" strokeLinecap="round" />
                  <rect x="1" y="1" width="38" height="38" rx="19" stroke="#8CB884" strokeOpacity="0.5"
                    strokeWidth="2" />
                </svg>
              </button>
              <h5>You are about to leave the My<span>Ampyra</span> website.</h5>
              <p>Please note:</p>
              <p>By clicking on this link, you are about to leave MyAmpyra.com, a website owned and operated by
                Merz Therapeutics, LLC. Merz Therapeutics, LLC. provides these links as a service to its
                website visitors and users; however, it does not endorse or take any responsibility for the
                information presented on any websites but its own.</p>
              <div className="button_container">
                <a id="stay_button" className="button" data-bs-dismiss="modal" href="#">No thanks, I'll stay</a>
                <a id="leave_button" className="button" href="#" target="_blank">Take me there</a>
              </div>
            </div>
          </div>
        </div>
      </div> 
      */}
    </>
  )
}