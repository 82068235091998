import React from "react";

export default function Isi() {
    
    return (
   
        <section className="isi-section" id="isi-section-fixed">
        <div className="container">
            <h5>INDICATION & IMPORTANT SAFETY INFORMATION</h5>
            <h6>Indication:
                <span> AMPYRA (dalfampridine) is a prescription medicine indicated to help improve walking in adults with
                    multiple sclerosis (MS). This was demonstrated by an increase in walking speed.</span>
            </h6>
            <h6>Important Safety Information</h6>
            <p className="margin-5">Do not take AMPYRA if you:</p>
            <ul>
                <li>have ever had a seizure,</li>
                <li>have certain types of kidney problems, or</li>
                <li>are allergic to dalfampridine <span>(4-aminopyridine),</span> the active ingredient in AMPYRA.</li>
            </ul>
            <p>Take AMPYRA exactly as prescribed by your doctor.</p>
            <p className="margin-5">Before taking AMPYRA, tell your doctor if you:</p>
            <ul>
                <li>have any other medical conditions</li>
                <li>are taking compounded <span>4-aminopyridine</span></li>
                <li> are taking any other prescription or OTC medicines, such as cimetidine</li>
                <li>are pregnant or plan to become pregnant. It is not known if AMPYRA will harm your unborn baby.
                </li>
                <li>are breastfeeding or plan to breastfeed. It is not known if AMPYRA passes into your breast milk.
                    Talk with your healthcare provider about the best way to feed your baby if you take AMPYRA.
                </li>
            </ul>
            <p>Stop taking AMPYRA and call your doctor right away if you have a seizure while taking AMPYRA. You could
                have a seizure even if you never had a seizure before. Your chance of having a seizure is higher if you
                take too much AMPYRA or if your kidneys have a mild decrease of function, which is common after age 50.
                Your doctor may do a blood test to check how well your kidneys are working before you start AMPYRA.
            </p>
            <p>AMPYRA should not be taken with other forms of 4-aminopyridine (4-AP, fampridine), since the active
                ingredient is the same.</p>
            <p>AMPYRA may cause dizziness or vertigo. If you have these symptoms do not drive, operate machinery or
                do
                other dangerous activities.</p>
            <p>AMPYRA may cause serious side effects, including severe allergic reactions. Stop taking AMPYRA and
                call
                your doctor right away or get emergency medical help if you have shortness of breath or trouble
                breathing, swelling of your throat or tongue, or hives.</p>
            <p>The most common side effects for AMPYRA in MS patients were urinary tract infection; trouble
                sleeping;
                dizziness; headache; nausea; weakness; back pain; problems with balance; multiple sclerosis relapse;
                burning, tingling, or itching of your skin; irritation in your nose and throat; constipation;
                indigestion; and pain in your throat.</p>
            <p><b>Please see the <a href="https://ampyra.com/medication-guide.pdf" target="_blank" id="HP_ISI">Patient
                Medication Guide</a>.</b></p>
        </div>
    </section>
    )
}