import { useLocation } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';

export default function WallkingAssessment() {

  const location = useLocation();
  let queryString = window.location.search;
  queryString = atob(queryString.substring(5, queryString.length))
  const urlParams = new URLSearchParams(queryString);
  const answer1 = urlParams.get('answer1');
  const answer2 = urlParams.get('answer2');
  const answer3 = urlParams.get('answer3');
  const answer4 = urlParams.get('answer4');
  const answer5 = urlParams.get('answer5');

  async function ModifyPdf() {
    const url = '/assets/pdf/self_assessment.pdf'
    let imageName = '/assets/images/answer-mark.png'
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    const pages = pdfDoc.getPages()
    const firstPage = pages[0]
    let radius = 50;
    const pngUrl = imageName;
    const pngImageBytes = await fetch(pngUrl).then((res) => res.arrayBuffer())
    const pngImage = await pdfDoc.embedPng(pngImageBytes)
    const pngDims = pngImage.scale(.55)

    const date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    // This arrangement can be altered based on how we want the date's format to appear.
    let currentDate = `${month}/${day}/${year}`;

    const fontSize = 30
    firstPage.drawText('Honey', {
      x: 481,
      y: 729,
      size: 10,
      //   color: rgb(0, 0.53, 0.71),
    })

    firstPage.drawText(currentDate, {
      x: 502,
      y: 681,
      size: 10,
      //   color: rgb(0, 0.53, 0.71),
    })

    switch (answer1) {
      case 'X5Y':
        firstPage.drawImage(pngImage, { x: 48, y: 532, width: 58, height: 20, })
        break;
      case 'P8R':
        firstPage.drawImage(pngImage, { x: 110, y: 532, width: 51, height: 20, })
        break;
      case 'A2S':
        firstPage.drawImage(pngImage, { x: 165, y: 532, width: 66.2, height: 20, })
        break;
      case 'B9T':
        firstPage.drawImage(pngImage, { x: 236.5, y: 532, width: 63, height: 20, })
        break;
      case 'L3M':
        firstPage.drawImage(pngImage, { x: 304, y: 532, width: 62, height: 20, })
        break;
      default:
        firstPage.drawImage(pngImage, { x: 48, y: 532, width: 58, height: 20, })
        break;

    }

    switch (answer2) {
      case 'X5Y':
        firstPage.drawImage(pngImage, { x: 48, y: 472, width: 58, height: 20, })
        break;
      case 'P8R':
        firstPage.drawImage(pngImage, { x: 110, y: 472, width: 51, height: 20, })
        break;
      case 'A2S':
        firstPage.drawImage(pngImage, { x: 165, y: 472, width: 66.2, height: 20, })
        break;
      case 'B9T':
        firstPage.drawImage(pngImage, { x: 236.5, y: 472, width: 63, height: 20, })
        break;
      case 'L3M':
        firstPage.drawImage(pngImage, { x: 304, y: 472, width: 62, height: 20, })
        break;
      default:
        firstPage.drawImage(pngImage, { x: 48, y: 472, width: 58, height: 20, })
        break;
    }

    switch (answer3) {
      case 'X5Y':
        firstPage.drawImage(pngImage, { x: 48, y: 400, width: 58, height: 20, })
        break;
      case 'P8R':
        firstPage.drawImage(pngImage, { x: 110, y: 400, width: 51, height: 20, })
        break;
      case 'A2S':
        firstPage.drawImage(pngImage, { x: 165, y: 400, width: 66.2, height: 20, })
        break;
      case 'B9T':
        firstPage.drawImage(pngImage, { x: 236.5, y: 400, width: 63, height: 20, })
        break;
      case 'L3M':
        firstPage.drawImage(pngImage, { x: 304, y: 400, width: 62, height: 20, })
        break;
      default:
        firstPage.drawImage(pngImage, { x: 48, y: 400, width: 58, height: 20, })
        break;
    }

    switch (answer4) {
      case 'X5Y':
        firstPage.drawImage(pngImage, { x: 48, y: 340, width: 58, height: 20, })
        break;
      case 'P8R':
        firstPage.drawImage(pngImage, { x: 110, y: 340, width: 51, height: 20, })
        break;
      case 'A2S':
        firstPage.drawImage(pngImage, { x: 165, y: 340, width: 66.2, height: 20, })
        break;
      case 'B9T':
        firstPage.drawImage(pngImage, { x: 236.5, y: 340, width: 63, height: 20, })
        break;
      case 'L3M':
        firstPage.drawImage(pngImage, { x: 304, y: 340, width: 62, height: 20, })
        break;
      default:
        firstPage.drawImage(pngImage, { x: 48, y: 340, width: 58, height: 20, })
        break;
    }

    switch (answer5) {
      case 'X5Y':
        // Answer 1-1
        firstPage.drawImage(pngImage, { x: 48, y: 280, width: 58, height: 20, })
        break;
      case 'P8R':
        // Answer 1-2
        firstPage.drawImage(pngImage, { x: 110, y: 280, width: 51, height: 20, })
        break;
      case 'A2S':
        // Answer 1-1
        firstPage.drawImage(pngImage, { x: 165, y: 280, width: 66.2, height: 20, })
        break;
      case 'B9T':
        // Answer 1-1
        firstPage.drawImage(pngImage, { x: 236.5, y: 280, width: 63, height: 20, })
        break;
      case 'L3M':
        // Answer 1-1
        firstPage.drawImage(pngImage, { x: 304, y: 280, width: 62, height: 20, })
        break;
      default:
        firstPage.drawImage(pngImage, { x: 48, y: 280, width: 58, height: 20, })
        break;
    }

    const pdfBytes = await pdfDoc.save();

    // Create a Blob object from the binary PDF data
    const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });

    // Create a URL for the Blob object
    const pdfUrl = URL.createObjectURL(pdfBlob);

    // Create a link element
    const downloadLink = document.getElementById('pdf');
    downloadLink.href = pdfUrl;
    downloadLink.download = 'self_assessment.pdf';
    downloadLink.textContent = 'self_assessment.pdf';
    // downloadLink.click();
    document.body.appendChild(downloadLink);

    // Trigger the click event
    downloadLink.click();
    // Remove the link from the DOM after the click event
    document.body.removeChild(downloadLink);
    window.close();
  }

  useEffect(() => {
    ModifyPdf()
  }, [])
  return (
    <>
      <a className="content" id="pdf"></a>
    </>
  )
}