import logo from './logo.svg';
import './App.css';
import Home from './views/Pages/Home';
import { BrowserRouter, HashRouter, Route, Routes, useParams } from 'react-router-dom';
import './style/style.css';
import './style/responsive.css';
import "bootstrap/dist/css/bootstrap.min.css";
import Contact from './views/Pages/Contact';
import Error from './views/Pages/Error';
import DashboardGeneric from './views/Pages/dashboard-generic';
import Dashboard from './views/Pages/dashboard';
import WallkingAssessment from './views/Pages/walking-assessment-pdf';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/contact' element={<Contact />} />
          {/* <Route path="/404" element={<Error />} /> */}
          <Route path="*" element={<Error />} />
          <Route path="/dashboard-generic" element={<DashboardGeneric />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path='/walking-assessment-pdf' element={<WallkingAssessment />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
